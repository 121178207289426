import { ShepherdService } from 'angular-shepherd';
import Step from 'shepherd.js/src/types/step';
import { ProductTourService } from '../../services/product-tour.service';
import { offset } from '@floating-ui/dom';

export function getSteps(service: ShepherdService, productTourService: ProductTourService): Step.StepOptions[] {
	const productToursElement: Element = document.querySelector('.desktop .sidebar-container .sidebar-product-tours .paint-product-tours');
	let productTourClickBind: () => void;
	let cancelled = false;

	function handleCompleteTour() {
		if (productToursElement && productTourClickBind) {
			productToursElement.removeEventListener('click', productTourClickBind);
		}
		if (cancelled) {
			productTourService.saveTourProgress('Welcome');
		} else {
			service.complete();
		}
	}

	return [
		{
			id: 'welcome1',
			title: 'Welcome to PaintProjex!',
			text: 'If this is your first time using PaintProjex, we\'ve developed some introductory product tours. Do you want to try one now?',
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			buttons: [
				{
					action: () => {
						handleCompleteTour();
					},
					text: 'Skip',
				},
				{
					action: () => {
						service.next();
					},
					text: 'Continue',
				},
			],
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			when: {
				cancel: () => {
					cancelled = true;
					handleCompleteTour();
				},
			},
		},
		{
			id: 'welcome2',
			title: 'Project Tours',
			text: 'Click on the highlighted Project Tour icon to see a list of tours.',
			attachTo:
				{
					element: '.desktop .sidebar-container .sidebar-product-tours paint-product-tour .paint-product-tours',
					on: 'top-end',
				},
			modalOverlayOpeningRadius: 5,
			modalOverlayOpeningPadding: 5,
			floatingUIOptions: {
				middleware: [offset({ mainAxis: 30, crossAxis: 24 })],
			},
			highlightClass: 'highlight',
			scrollTo: {
				block: 'center',
				behavior: 'smooth',
			},
			beforeShowPromise: function() {
				return new Promise(function(resolve) {
					setTimeout(function() {
						window.scrollTo(0, 0);
						resolve(null);
					}, 500);
				});
			},
			when: {
				show: () => {
					if (productToursElement) {
						productTourClickBind = handleCompleteTour.bind(this);
						productToursElement.addEventListener('click', productTourClickBind);
					}
				},
				cancel: () => {
					cancelled = true;
					handleCompleteTour();
				},
			},
		},
	];
}