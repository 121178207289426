import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreModule } from '../../../core/core.module';
import { CoatProductsTableComponent } from './coat-products-table.component';
import {
	MetricImperialInputComponent
} from '../../../components/forms/metric-imperial-input/metric-imperial-input.component';
import { AreaUnitPipe } from '../../../pipes/area.pipe';

@NgModule({
	declarations: [CoatProductsTableComponent],
	imports: [CoreModule, CommonModule, MetricImperialInputComponent, AreaUnitPipe],
	exports: [CoatProductsTableComponent],
})
export class CoatProductsTableModule {}
