import { Routes } from '@angular/router';

import {
	NavigationWarningProjectSessionGuard,
} from '../../@itc-core/components/navigation-warning/common/navigation-warning-project-session.guard';
import { NavigationWarningGuard } from '../../@itc-core/components/navigation-warning/common/navigation-warning.guard';
import { CompanyAddEditComponent } from '../pages/company/company-add-edit/company-add-edit.component';
import { CompanyListComponent } from '../pages/company/company-list/company-list.component';
import { DashboardComponent } from '../pages/dashboard/dashboard.component';
import {
	EstimatorUserToolLibraryComponent,
} from '../pages/estimator-user-tool-library/estimator-user-tool-library.component';
// import { MeasuringToolComponent } from '../pages/measure-tool/measuring-tool.component';
import { ProjectDetailsComponent } from '../pages/project/project-details/project-details.component';
import { UserProfileComponent } from '../pages/user-profile/user-profile.component';
import { AuthGuard, NoAuthGuard } from '../route-guards/auth-guard.service';
import { ContractsAndAboveGuard } from '../route-guards/contracts-and-above.guard';
import { CreateNewProjectGuard } from '../route-guards/create-new-project.guard';
import { EntityOwnerGuard } from '../route-guards/entity-owner.guard';
import { ProjectSessionCreateGuard } from '../route-guards/project-session-create-guard.service';
import { ProjectSessionGuard } from '../route-guards/project-session-guard.service';
import { QuoteApprovalGuard } from '../route-guards/quote-approval.guard';
import { SuperAdminGuard } from '../route-guards/super-admin.guard';
import { ViewerAndAboveGuard } from '../route-guards/viewer-and-above-guard.guard';
import { featureFlagGuard } from '../route-guards/feature-flag.guard';
import { Feature } from './feature';
import { EmployeeGuard } from '../route-guards/employee.guard';
import { GeneralLayoutComponent } from '../layouts/general-layout/general-layout.component';
import { RedirectEmployeeGuard } from '../route-guards/redirect-employee.guard';
import { FullScreenLayoutComponent } from '../layouts/full-screen-layout/full-screen-layout.component';
import { ShiftsLayoutComponent } from '../layouts/shifts-layout/shifts-layout.component';
import { GetCloneProjectResolver } from '../services/project.service';
import { MeasuringToolComponent } from '../pages/measuring-tool/measuring-tool.component';
import { ProjectRequestGuard } from '../route-guards/project-request.guard';

export const router: Routes = [
	{
		path: 'login',
		loadChildren: () => import('../pages/login/login-form/common/login-form.module').then(m => m.LoginFormModule),
		canActivate: [NoAuthGuard],
	},
	{
		path: 'signup',
		loadChildren: () => import('../pages/login/signup-form/common/signup-form.module').then(m => m.SignupFormModule),
		canActivate: [NoAuthGuard],
	},
	{
		path: 'createaccount',
		loadChildren: () => import('app/pages/login/create-account-form/common/create-account-form.module').then(m => m.CreateAccountFormModule),
		canActivate: [NoAuthGuard],
	},
	{
		path: 'forgotpassword',
		loadChildren: () => import('app/pages/login/forgot-password-form/common/forgot-password-form.module').then(m => m.ForgotPasswordFormModule),
		canActivate: [NoAuthGuard],
	},
	{
		path: 'resetpassword/:key',
		loadChildren: () => import('app/pages/login/reset-password-form/common/reset-password-form.module').then(m => m.ResetPasswordFormModule),
		canActivate: [NoAuthGuard],
	},
	{
		path: 'subscription',
		loadChildren: () => import('app/pages/subscription/subscription.module').then(m => m.SubscriptionModule),
		canActivate: [EntityOwnerGuard],
	},
	{
		path: 'approval/:id',
		loadChildren: () => import('app/pages/quote-approval/common/quote-approval.module').then(m => m.QuoteApprovalModule),
		canActivate: [QuoteApprovalGuard],
	},
	{
		path: 'approval/:id/approved',
		loadChildren: () => import('app/pages/quote-approval/components/quote-approved/common/quote-approved.module').then(m => m.QuoteApprovedModule),
		canActivate: [QuoteApprovalGuard],
	},
	{
		path: 'approval/:id/declined',
		loadChildren: () => import('app/pages/quote-approval/components/quote-declined/common/quote-declined.module').then(m => m.QuoteDeclinedModule),
		canActivate: [QuoteApprovalGuard],
	},
	{
		path: 'webhook',
		loadChildren: () => import('app/pages/external-accounting/webhook.module').then(m => m.WebhookModule),
	},
	{
		path: 'submit-project/:id',
		loadChildren: () => import('../pages/submit-project/common/submit-project.module').then(m => m.SubmitProjectModule),
		canActivate: [ProjectRequestGuard]
	},
	// GeneralLayoutComponent routes
	{
		path: '',
		component: GeneralLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
				resolve: DashboardComponent.routerResolvers,
				data: DashboardComponent.routerData,
			},
			{
				path: 'debug',
				loadChildren: () => import('../pages/debug/debug-page.module').then(m => m.DebugPageModule),
				canMatch: [featureFlagGuard(Feature.ScheduleTool)],
			},
			{
				path: 'projects',
				loadChildren: () => import('app/pages/project/project-search/project-search.module').then(m => m.ProjectSearchModule),
				resolve: ProjectDetailsComponent.routerEditResolversSearch,
				data: ProjectDetailsComponent.routerData,
				canActivate: [ViewerAndAboveGuard],
			},
			{
				path: 'live',
				children: [
					{
						path: '',
						redirectTo: 'projects',
						pathMatch: 'full',
					},
					{
						path: 'projects',
						loadChildren: () => import('app/pages/live-projects/live-projects.module').then(m => m.LiveProjectsModule),
						canActivate: [ContractsAndAboveGuard],
					},
					{
						path: 'forecasting',
						loadChildren: () => import('app/pages/forecasting/common/forecasting.module').then(m => m.ForecastingModule),
					},
				]
			},
			{
				path: 'leads',
				loadChildren: () => import('app/pages/leads/leads.module').then(m => m.LeadsModule),
				canActivate: [ContractsAndAboveGuard]
			},
			{
				path: 'project/:projectId',
				loadChildren: () => import('app/pages/project/common/project.module').then(m => m.ProjectModule),
				canActivate: [ProjectSessionCreateGuard],
				canDeactivate: [NavigationWarningProjectSessionGuard],
			},
			{
				path: 'measuring-tool/:projectId',
				loadChildren: () => import('app/pages/measure-tool/common/measuring-tool.module').then(m => m.MeasuringToolModule),
				canActivate: [ProjectSessionGuard],
				canDeactivate: [NavigationWarningProjectSessionGuard],
				resolve: MeasuringToolComponent.routerResolvers,
			},
			{
				path: 'measuring-tool/:projectId',
				component: FullScreenLayoutComponent,
				loadChildren: () => import('app/pages/measuring-tool/measuring-tool.module').then(m => m.MeasuringToolModule),
				canActivate: [ProjectSessionGuard],
				canDeactivate: [NavigationWarningProjectSessionGuard],
				resolve: MeasuringToolComponent.routerResolvers,
			},
			{
				path: 'clients',
				loadChildren: () => import('app/pages/company/company-list/company-list.module').then(m => m.CompanyListModule),
				resolve: CompanyListComponent.routerClientResolvers,
				data: CompanyListComponent.routerClientData,
				canActivate: [ViewerAndAboveGuard],
			},
			{
				path: 'client',
				loadChildren: () => import('app/pages/company/company-add-edit/company-add-edit.module').then(m => m.CompanyAddEditModule),
				resolve: CompanyAddEditComponent.routerAddClientResolvers,
				data: CompanyAddEditComponent.routerClientData,
				canActivate: [ViewerAndAboveGuard],
				canDeactivate: [NavigationWarningGuard],
			},
			{
				path: 'client/:id',
				loadChildren: () => import('app/pages/company/company-add-edit/company-add-edit.module').then(m => m.CompanyAddEditModule),
				resolve: CompanyAddEditComponent.routerClientResolvers,
				data: CompanyAddEditComponent.routerClientData,
				canActivate: [ViewerAndAboveGuard],
				canDeactivate: [NavigationWarningGuard],
			},
			{
				path: 'libraries',
				loadChildren: () => import('app/pages/libraries/libraries/common/libraries.module').then(m => m.LibrariesModule),
				canActivate: [ContractsAndAboveGuard],
			},
			{
				path: 'sales',
				loadChildren: () => import('app/pages/reports/common/reports.module').then(m => m.ReportsModule),
				canActivate: [featureFlagGuard(Feature.Reporting), ContractsAndAboveGuard],
			},
			{
				path: 'invoices',
				loadChildren: () => import('app/pages/invoices/common/invoices.module').then(m => m.InvoicesModule),
				canActivate: [ContractsAndAboveGuard],
			},
			{
				path: 'user-tools',
				loadChildren: () => import('app/pages/estimator-user-tool-library/common/estimator-user-tool-library.module').then(m => m.EstimatorUserToolLibraryModule),
				resolve: EstimatorUserToolLibraryComponent.routerResolvers,
				canDeactivate: [ProjectSessionGuard],
			},
			{
				path: 'user',
				loadChildren: () => import('app/pages/user-profile/user-profile.module').then(m => m.UserProfileModule),
				resolve: UserProfileComponent.routerResolvers,
			},
			{
				path: 'admin',
				loadChildren: () => import('app/pages/settings/settings.module').then(m => m.SettingsModule),
				canActivateChild: [SuperAdminGuard],
			},
			{
				path: 'help',
				loadChildren: () => import('app/pages/help/common/help.module').then(m => m.HelpModule),
			},
			{
				path: 'subscription',
				loadChildren: () => import('app/pages/subscription/subscription.module').then(m => m.SubscriptionModule),
				canActivate: [EntityOwnerGuard],
			},
			{
				path: 'alerts',
				loadChildren: () => import('app/pages/alert/common/alert.module').then(m => m.AlertModule),
			},
			{
				path: 'schedule',
				loadChildren: () => import('app/pages/schedule/schedule.module').then(m => m.ScheduleModule),
				canActivate: [featureFlagGuard(Feature.ScheduleTool), RedirectEmployeeGuard],
			},
			{
				path: 'external-accounting',
				loadChildren: () => import('app/pages/external-accounting/external-accounting.module').then(m => m.ExternalAccountingModule),
			},
			{
				path: 'help-centre',
				loadChildren: () => import('app/pages/help-centre/help-centre.module').then(m => m.HelpCentreModule),
			}
		],
	},
	{
		path: 'create-project',
		component: FullScreenLayoutComponent,
		loadChildren: () => import('app/pages/project/project-details/project-details.module').then(m => m.ProjectDetailsModule),
		resolve: { ...ProjectDetailsComponent.routerAddEditProjectResolver, cloneProject: GetCloneProjectResolver },
		data: ProjectDetailsComponent.routerData,
		canActivate: [CreateNewProjectGuard, ViewerAndAboveGuard],
		canDeactivate: [NavigationWarningGuard],
	},
	// {
	// 	path: 'measure/:projectId',
	// 	component: FullScreenLayoutComponent,
	// 	loadChildren: () => import('app/pages/measuring-tool/measuring-tool.module').then(m => m.MeasuringToolModule),
	// 	canActivate: [ProjectSessionGuard],
	// 	canDeactivate: [NavigationWarningProjectSessionGuard],
	// 	resolve: MeasuringToolComponent.routerResolvers,
	// },
	{
		path: 'shifts',
		component: ShiftsLayoutComponent,
		canActivate: [EmployeeGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('../pages/schedule/shifts/shifts.module').then(m => m.ShiftsModule),
			},
		],
	},
	{
		path: '**',
		redirectTo: '',
	},
];
