// Framework imports
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { Constants } from '../../../../../../helpers';
import { PostOrder } from '../../../../../../interfaces';
import { MeasuringToolTask } from '../../../measuring-tool.interfaces';
import { MeasuringToolPage } from '../../../measuring-tool.models';
import { MeasuringToolPageResponse, MeasuringToolPagesResponse } from '../../page-panel/common/page-panel.interfaces';
import { ProjectPagesUpdate } from './measuring-tool-layout.interfaces';

@Injectable({
	providedIn: 'root',
})
export class MeasuringToolLayoutDataService {
	constructor(private http: HttpClient) {}

	/**
	 * Retrievs a Measuring Tool Page from a given id
	 * @returns {Observable<MeasuringToolPage>}
	 * @param pageId
	 */
	public details(pageId: string): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/details`, { pageId }).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Adds a new Page, sets current page
	 * @returns {Observable<MeasuringToolPage>}
	 * @param page
	 */
	public addPage(page: MeasuringToolPage): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/create`, this.getPostObject(page)).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	public clonePage(pageId: string, pageUpdate: Partial<MeasuringToolPage>): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/clone`, { pageId, pageUpdate }).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	public importPages(projectId: string, variationId: string, pages: MeasuringToolPage[]): Observable<MeasuringToolPage[]> {
		return this.http
			.post<any>(`${Constants.BASE_API_URL}/measuring-tool/import`, { projectId, variationId, pageIds: pages.map(p => p.id) })
			.pipe(map(res => res.pages.map(value => new MeasuringToolPage(value))));
	}

	public editOrder(items: PostOrder[], projectId: string): Observable<MeasuringToolPage[]> {
		return this.http
			.post<MeasuringToolPagesResponse>(`${Constants.BASE_API_URL}/measuring-tool/edit-order`, { items, projectId })
			.pipe(map(res => res.pages.map((value: any) => new MeasuringToolPage(value))));
	}

	/**
	 * Gets all active pages
	 * @returns {Observable<MeasuringToolPage[]>}
	 */
	public getAllPages(projectId: string, variationId?: string, includeVariations?: boolean): Observable<MeasuringToolPage[]> {
		return this.http
			.post<MeasuringToolPagesResponse>(`${Constants.BASE_API_URL}/measuring-tool/list`, { projectId, variationId, includeVariations })
			.pipe(map(res => res.pages.map(p => new MeasuringToolPage(p))));
	}

	/**
	 * Gets all boqs that belong to the pages
	 * @returns {Observable<any>}
	 */
	public getMeasuringToolBoqIds(projectId: string): Observable<string[]> {
		return this.http.post<string[]>(`${Constants.BASE_API_URL}/measuring-tool/list-measuring-tool-boq-ids`, { projectId }).pipe(
			map((res: any) => {
				return res.boqs;
			})
		);
	}

	/**
	 * Update page
	 * @param page
	 * @returns {Observable<MeasuringToolPage>}
	 */
	public updatePage(page: Partial<MeasuringToolPage>): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/edit`, this.getPostObject(page)).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Get page details
	 * @param pageId
	 */
	public getPageDetails(pageId: string): Observable<MeasuringToolPage> {
		const body = { pageId };
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/details`, body).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Updates a measuring tool page userTool to set the page name to a charge subLocation2 name
	 * @param projectId
	 * @param boqId
	 * @param subLocation2
	 */
	public updateUserToolName(projectId: string, boqIds: string[], subLocation2: string): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/edit-boq-user-tool-sublocation2`, { projectId, boqIds, subLocation2 }).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Updates all measuring tool pages from a particular project
	 * @param projectId
	 * @param pageUpdate
	 */
	public updateProjectPages(projectId: string, pageUpdate: Partial<ProjectPagesUpdate>): Observable<MeasuringToolPage[]> {
		return this.http
			.post<MeasuringToolPagesResponse>(`${Constants.BASE_API_URL}/measuring-tool/project-bulk-edit`, { projectId, pageUpdate })
			.pipe(map(res => res.pages.map((value: any) => new MeasuringToolPage(value))));
	}

	/**
	 * Update a page's tasks
	 * @param pageId
	 * @param tasks
	 */
	public updateTasks(pageId: string, tasks: MeasuringToolTask[]): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/update-tasks`, { pageId, tasks }).pipe(map(res => res.page));
	}

	/**
	 * Deactivate page
	 * @param pageId
	 * @param isActive
	 * @returns {Observable<MeasuringToolPage>}
	 */
	public toggleActiveState(pageId: string, isActive: boolean): Observable<MeasuringToolPage> {
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/edit-active`, { pageId, isActive }).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Post to upload-file action of endpoint and return an instance of modelType
	 * @param projectId
	 * @param pageId
	 * @param blob
	 */
	public uploadThumbnail(projectId: string, pageId: string, blob: Blob): Observable<MeasuringToolPage> {
		const formData = new FormData();
		formData.append('thumbnail', blob);
		formData.append('projectId', projectId);
		formData.append('pageId', pageId);
		return this.http.post<MeasuringToolPageResponse>(`${Constants.BASE_API_URL}/measuring-tool/upload-thumbnail`, formData).pipe(
			map(res => {
				return new MeasuringToolPage(res.page);
			})
		);
	}

	/**
	 * Taken from base model - did not use base model for measuring tool page as it does not support
	 * partial objects to be sent in update requests
	 * Returns an object to post to the server. All properties that have an id are replaced with their id.
	 */
	private getPostObject(measuringToolPage: Partial<MeasuringToolPage>) {
		delete measuringToolPage.boqToolsMap;

		const postObject: MeasuringToolPage = JSON.parse(JSON.stringify(measuringToolPage));

		this.replaceObjectsWithIds(postObject, []);

		return postObject;
	}

	/**
	 * Recursive function to replace objects with their ids.
	 * @param obj
	 * @param ignoreList
	 */
	private replaceObjectsWithIds(obj: any, ignoreList: Array<string> = []) {
		// Loop through the objects keys
		for (const key in obj) {
			if (ignoreList.includes(key)) {
				continue;
			}
			// If the objects own property is an object, test if it has an id
			if (obj[key] && obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
				if (obj[key].hasOwnProperty('id')) {
					// This property has an id, set the object to the id
					obj[key] = obj[key]['id'];
				} else {
					// This property has no id field, recursively check it's properties
					this.replaceObjectsWithIds(obj[key], ignoreList);
				}
			}
		}
	}
}
