import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { SearchParams } from '../../components/project-filter/common/project-filter.interfaces';
import { ProjectFilterModel } from '../../components/project-filter/common/project-filter.models';
import { ProjectFilterComponent } from '../../components/project-filter/project-filter.component';
import { CommunicationService } from '../../core/components/communication/common/communication.service';
import { DashboardStrikeRateModel } from '../../core/components/dashboard-strike-rate/common/dashboard-strike-rate.models';
import { Constants, DateHelper, TEXT } from '../../helpers';
import { ProjectSearch, State, SuccessRatesData } from '../../interfaces';
import { DivisionModel, UserModel } from '../../models';
import { AuthService } from '../../services/auth.service';
import { DashboardService } from '../../services/dashboard.service';
import { GetDivisionsResolver } from '../../services/division.service';
import { S3Service } from '../../services/s3.service';
import { GetStatesResolver } from '../../services/state.service';
import { GetAssignedTasksResolver } from '../../services/task.service';
import { GetCurrentUserResolver } from '../../services/user.service';
import { SchedulingToolDataItem, SearchData, SharedSearchData } from './common/dashboard.interface';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	@ViewChild(ProjectFilterComponent, { static: false })
	private projectFilterComponent: ProjectFilterComponent;
	/**
	 * Router config for resolvers
	 * @type {}
	 */
	// tslint:disable-next-line:member-ordering
	public static routerResolvers = {
		divisions: GetDivisionsResolver,
		user: GetCurrentUserResolver,
		states: GetStatesResolver,
		tasks: GetAssignedTasksResolver,
	};

	/**
	 * Router config for data
	 * @type {}
	 */
	// tslint:disable-next-line:member-ordering
	public static routerData = {
		resolverCodeTypes: [],
		graphDateRange: {
			userId: '',
			dateTo: new Date(),
			dateFrom: DateHelper.priorMonth(Date()),
		},
	};

	// Dates
	public searchParams: ProjectSearch = {
		dateFrom: moment().subtract(1, 'year').set('month', 6).startOf('month').toDate(),
		dateTo: moment().set('month', 5).endOf('month').toDate(),
		division: undefined,
		estimators: [],
		state: undefined,
	};

	public communicationObservable: Observable<String[]>;
	public currentUser: UserModel;
	public dashboardStrikeRateInputConfig: DashboardStrikeRateModel = new DashboardStrikeRateModel();
	public dashboardSuccessRatesSubscription: Subscription;
	public divisionName: string;
	public divisionId: string;
	public divisionUsers: UserModel[];
	public imageUrl;
	public isCurrentUserAdmin: boolean = false;
	public projectFilterInputConfig: ProjectFilterModel;
	public selectedUsers: UserModel[] = [];
	public selectedUser: UserModel;
	public searchData: SearchData;
	public sharedSearchData: SharedSearchData;
	public schedulingToolData: SchedulingToolDataItem[];
	public schedulingToolDataPromise: Promise<void>;
	public textConstants = TEXT;
	public userImageUrlSubscription: Subscription;

	constructor(
		private authService: AuthService,
		private dashboardService: DashboardService,
		private route: ActivatedRoute,
		private s3Service: S3Service,
		private communicationService: CommunicationService,
		private changeDetectorRef: ChangeDetectorRef,
		private localStorageService: LocalStorageService,
	) {
		this.communicationObservable = this.communicationService.paintCommunication;
	}

	public ngOnInit(): void {
		this.route.data.subscribe(({ user }) => {
			this.currentUser = user;
			this.localStorageService.updateSessionUser(this.currentUser);
			this.searchParams.estimators.push(this.currentUser.id);
			this.isCurrentUserAdmin = this.authService.hasRolePermission(Constants.USER_ROLES.admin);
		});
		this.setProjectFilterInputConfig();

	}

	public ngAfterContentChecked() {
		this.changeDetectorRef.detectChanges();
	}

	public selectDivision(division: any): void {
		this.divisionName = division.value;
		this.divisionId = division.key;
		this.getDivisionUsers();
	}

	private getDivisionUsers() {
		this.divisionUsers = [];
		if (this.divisionId && this.divisionId !== 'all') {
			this.divisionUsers = this.projectFilterInputConfig.divisions.find(division => division.id === this.divisionId).users;
		}
	}

	/**
	 * Selects a user for display and notifies the child components.
	 * @param users
	 */
	public selectUsers(users: UserModel[]): void {
		this.selectedUser = null;
		this.selectedUsers = [];
		this.selectedUsers.push(...users);
		if (users.length === 1) {
			this.selectedUser = this.selectedUsers[0];
			this.searchParams.estimators.push(this.selectedUser.id);
		} else {
			for (const user of this.selectedUsers) {
				this.searchParams.estimators.push(user.id);
			}
		}

		// Update graphs / stats for new user based on current filter terms
		if (this.selectedUser && this.divisionName) {
			this.currentUser = this.selectedUser;
		} else {
			this.currentUser = this.localStorageService.getUserDetails();
		}
		this.projectFilterComponent.updateSearch();
	}

	public setProjectFilterInputConfig(): void {
		let divisionsData: DivisionModel[] = [];
		let statesData: State[] = [];

		this.route.data.subscribe(({ divisions, states }) => {
			divisionsData = divisions;
			statesData = states;
		});

		const dateRange = DateHelper.getFinancialYearDateRange();
		const dateFrom = dateRange.fromDate.toDate();
		const dateTo = dateRange.toDate.toDate();

		this.projectFilterInputConfig = {
			divisions: divisionsData,
			defaultDates: {
				startDate: dateFrom,
				endDate: dateTo,
			},
			states: statesData,
		};
	}

	/**
	 * Sets new dashboard data.
	 * @param event
	 */
	public setSearchData(event: SearchParams) {
		const estimators: string[] = [];
		const selectedUserIds = this.selectedUsers.map((user: UserModel) => user.id);

		for (const user of this.selectedUsers) {
			estimators.push(user.id);
		}

		this.searchParams = {
			dateFrom: event.dates.startDate,
			dateTo: event.dates.endDate,
			division: event.division ? event.division.id : undefined,
			estimators: estimators,
			state: event.state ? event.state : undefined,
			status: event.status ? event.status : [],
		};

		this.searchData = {
			selectedUsers: this.selectedUsers,
			search: this.searchParams,
		};
		this.sharedSearchData = { selectedUserIds, search: this.searchParams };

		this.setDashboardStrikeRateInputConfig();
		this.updateProjectsSchedulesData(this.searchParams);
	}

	/**
	 * Fetches new projects schedules data
	 * @param projectSearch
	 */
	public updateProjectsSchedulesData(projectSearch: ProjectSearch) {
		const { dateTo, dateFrom, division, state, status } = projectSearch;

		this.schedulingToolDataPromise = new Promise((resolve, reject) => {
			const selectedUserIds = this.selectedUsers.map((user: UserModel) => user.id);

			this.dashboardService.getSchedulingToolData(selectedUserIds, dateTo, dateFrom, division, state, status).subscribe((data: SchedulingToolDataItem[]) => {
				this.schedulingToolData = data;
				resolve();
			}, reject);
		});
	}

	private setDashboardStrikeRateInputConfig(): void {
		this.imageUrl = [];
		if (this.currentUser && this.currentUser.displayPictureKey && this.sharedSearchData?.selectedUserIds.length === 1 && this.sharedSearchData?.selectedUserIds[0] === this.currentUser.id) {
			this.userImageUrlSubscription = this.s3Service.getSignedUrl(this.currentUser.displayPictureKey)
				.pipe(
					finalize(() => {
						this.userImageUrlSubscription.unsubscribe();
					})
				).subscribe((url: string) => {
					this.imageUrl.push({
						url,
						fullName: this.currentUser.firstName + ' ' + this.currentUser.lastName
					});
				});
		} else if (this.divisionUsers) {
			for (const user of this.divisionUsers) {
				const fullName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
				if (user.displayPictureKey) {
					this.s3Service.getSignedUrl(user.displayPictureKey).subscribe(url => {
						this.imageUrl.push({ url, fullName: fullName });
					})
				} else {
					this.imageUrl.push({ url: '', fullName: fullName });
				}
			}
		}

		// Replace status search data with won and submitted only, as strike rate only displays data for won / submitted projects
		this.dashboardSuccessRatesSubscription = this.dashboardService
			.getSuccessRates(
				this.sharedSearchData?.selectedUserIds,
				this.searchData.search.dateTo,
				this.searchData.search.dateFrom,
				this.searchData.search.division,
				this.searchData.search.state,
				this.searchData.search.status
			)
			.pipe(
				finalize(() => {
					this.dashboardSuccessRatesSubscription.unsubscribe();
				})
			)
			.subscribe((data: SuccessRatesData) => {
				this.dashboardStrikeRateInputConfig = {
					userImages: this.imageUrl,
					profitAverage: data.averageProjectTotalProfit,
					profitLost: data.lostProjectsTotalProfit,
					profitSubmitted: data.submittedProjectsTotalProfit,
					profitWon: data.wonProjectsTotalProfit,
					profitTotal: data.projectsTotalProfit,
					projectsSubmitted: data.projectsSubmittedCount,
					projectsLost: data.projectsLostCount,
					projectsWon: data.projectsWonCount,
					revenueLost: data.lostProjectsNetTotalChargeOut,
					revenueSubmitted: data.submittedProjectsNetTotalChargeOut,
					revenueWon: data.wonProjectsNetTotalChargeOut,
					strikeRate: data.strikeRate * 100,
				};
			});
	}
}
