import { Component, Input, OnInit } from '@angular/core';

import { EMPTY, firstValueFrom, Observable } from 'rxjs';

import { Constants, ERROR_MESSAGES, SnackBarHelperComponent, ValidatorHelper } from '../../../helpers';
import {
	BoqUpdateData,
	EmbeddedBOQCoatProduct,
	GetBoqCoatingSystemCardValidation,
	GetBoqCoatingSystemProductValidation,
	Product,
	ProductApplicationMethod,
} from '../../../interfaces';
import { BoqModel, BrandModel } from '../../../models';
import { BoqService } from '../../../services/boq.service';
import { BrandService } from '../../../services/brand.service';
import { ProductService } from '../../../services/product.service';
import {
	CoatingSystemEditText
} from '../../../pages/project/project-details/boq/boq-coating-system-edit/common/boq-coating-system-edit.constants';

@Component({
	selector: 'app-coating-system-edit-dialog',
	templateUrl: './coating-system-edit-dialog.component.html',
	styleUrls: ['./coating-system-edit-dialog.component.scss'],
})
export class CoatingSystemEditDialogComponent implements OnInit {
	@Input()
	public projectId: string;
	@Input()
	public set isDisabled(isDisabled: boolean) {
		this._isDisabled = isDisabled;
		this.newCoatProductValidators = GetBoqCoatingSystemProductValidation(this.isDisabled);
		this.titleValidator = GetBoqCoatingSystemCardValidation();
		this.initCoatingSystemForm();
	}
	public get isDisabled(): boolean {
		return this._isDisabled;
	}

	@Input()
	public set boq(boq: BoqModel) {
		if (boq) {
			this._boq = new BoqModel(boq);
			this.initCoatingSystemForm();
			this.setupBrandFilters();
		}
	}
	public get boq(): BoqModel {
		return this._boq;
	}

	public allProducts: Array<Product> = [];
	public applicationMethods: ProductApplicationMethod[][] = [];
	public brands: BrandModel[] = [];
	public coatProductsValidators = [];
	public loadingBrandPromise: Promise<BrandModel[]>;
	public loadingProductsPromise: Promise<Product[]>;
	public newApplicationMethods: ProductApplicationMethod[] = [];
	public newCoatProduct: EmbeddedBOQCoatProduct;
	public newCoatProductValidators;
	public titleValidator;
	public newSelectedProductBrand: BrandModel;
	public percentages: Number[] = Constants.COATING_SYSTEM_PERCENTAGES;
	public selectedBrandProducts = {};
	public selectedProductBrand: BrandModel[] = [];

	private _boq: BoqModel;
	private _isDisabled: boolean = false;

	constructor(
		private snack: SnackBarHelperComponent,
		private boqService: BoqService,
		private productService: ProductService,
		private brandService: BrandService
	) {}

	public ngOnInit() {
		// this.loadingProductsPromise = this.productService
		//   .postList({ isActive: true })
		//   .toPromise();
		// this.loadingProductsPromise.then(products => {
		//   this.allProducts = products;
		//   this.setNewCoatProduct();
		// });

		// this.loadingBrandPromise = this.brandService
		//   .postList({ isActive: true })
		//   .toPromise();
		// this.loadingBrandPromise.then(brands => {
		//   this.brands = brands;
		// });

		this.loadingProducts().then(products => {
			this.allProducts = products;
			this.setNewCoatProduct();
		});

		this.loadingBrands().then(brands => {
			this.brands = brands;
		});
	}

	public async loadingProducts() {
		const products$ = this.productService.postList({ isActive: true });
		return await firstValueFrom(products$);
	}

	public async loadingBrands() {
		const brands$ = this.brandService.postList({ isActive: true });
		return await firstValueFrom(brands$);
	}

	public saveCoatingSystem(): Observable<BoqUpdateData> {
		const titleError = ValidatorHelper.checkErrors(this.titleValidator);
		if (titleError) {
			this.snack.snackError(titleError);
			return EMPTY;
		}
		for (const validation of this.coatProductsValidators) {
			const errors = ValidatorHelper.checkErrors(validation);
			if (errors) {
				this.snack.snackError(errors);
				return EMPTY;
			}
		}

		return this.boqService.editBoqWithUpdateData(this.boq, this.projectId);
	}

	/**
	 * Initialize coating system form.
	 */
	private initCoatingSystemForm() {
		if (this.boq && this.boq.coatingSystem && this.boq.coatingSystem.coatProducts && this.boq.coatingSystem.coatProducts.length > 0) {
			const isNewCoatingSystem: boolean = this.boq.coatingSystem.coatProducts[0].spreadRate === undefined;
			if (isNewCoatingSystem) {
				// Initialize rates only if the coating system has just been created.
				this.setCoatProductRates();
			}
			this.setupCoatProductsFormGroup();
			this.setupInitialApplicationMethods();
		}
	}

	/**
	 * Add the new coat product to the coating system coat products array, and clear it.
	 */
	public addNewCoatProduct(): void {
		//Concatenate error strings and trim in case whitespace is returned
		const errors = ValidatorHelper.checkErrors(this.newCoatProductValidators);
		if (errors) {
			this.snack.snackError(errors);
			return;
		}
		this.coatProductsValidators.push(GetBoqCoatingSystemProductValidation(this.isDisabled));
		this.boq.coatingSystem.coatProducts.push(this.newCoatProduct);
		this.applicationMethods.push(this.newCoatProduct.product.applicationMethods);
		this.selectedProductBrand.push(this.newSelectedProductBrand);

		this.setNewCoatProduct();
	}

	/**
	 * When application method selected, fill the spreadRate and targetRate fields.
	 * Populate targetRate using the boq's selected height range.
	 * If there is no method selected, clear the rates.
	 * @param method
	 * @param index   if no index, we're editing the new entry.
	 */
	public applicationMethodSelected(method: any, index?: number): void {
		if (index !== undefined) {
			this.boq.coatingSystem.coatProducts[index].spreadRate = 0;
			this.boq.coatingSystem.coatProducts[index].targetRate = 0;
			if (method) {
				this.boq.coatingSystem.coatProducts[index].spreadRate = method.spreadRate;

				if (this.boq.heightRange && method.heightRangeRates && method.heightRangeRates.length) {
					for (const range of method.heightRangeRates) {
						if (range.title === this.boq.heightRange) {
							this.boq.coatingSystem.coatProducts[index].targetRate = range.value;
						}
					}
				}
			}
		} else {
			this.newCoatProduct.spreadRate = 0;
			this.newCoatProduct.targetRate = 0;
			if (method) {
				this.newCoatProduct.spreadRate = method.spreadRate;

				if (this.boq.heightRange && method.heightRangeRates && method.heightRangeRates.length) {
					for (const range of method.heightRangeRates) {
						if (range.title === this.boq.heightRange) {
							this.newCoatProduct.targetRate = range.value;
						}
					}
				}
			}
		}

		for (const validation of this.coatProductsValidators) {
			const errors = ValidatorHelper.checkErrors(validation);
			if (errors) {
				return;
			}
		}
	}

	/**
	 * When product selected, populate application methods for the coatProduct.
	 * If product cleared, clear application methods and rates as well.
	 * @param product
	 * @param index   if no index, we're editing the new entry.
	 */
	public productSelected(product: Product, index?: number): void {
		if (index !== undefined) {
			this.applicationMethods[index] = [];
			this.boq.coatingSystem.coatProducts[index].applicationMethod = undefined;
			this.boq.coatingSystem.coatProducts[index].spreadRate = 0;
			this.boq.coatingSystem.coatProducts[index].targetRate = 0;

			if (product) {
				this.applicationMethods[index] = product.applicationMethods;
			}
		} else {
			this.newApplicationMethods = [];
			this.newCoatProduct.applicationMethod = undefined;
			this.newCoatProduct.spreadRate = 0;
			this.newCoatProduct.targetRate = 0;

			if (product) {
				this.newApplicationMethods = product.applicationMethods;
			}
		}

		for (const validation of this.coatProductsValidators) {
			const errors = ValidatorHelper.checkErrors(validation);
			if (errors) {
				return;
			}
		}
	}

	/**
	 * Removes an added coat product from the embedded coating system.
	 * @param index
	 */
	public removeCoatProduct(index: number): void {
		if (index !== -1) {
			if (this.boq.coatingSystem.coatProducts.length === 1) {
				this.snack.snackError(ERROR_MESSAGES.atLeastOneItem);
				return;
			}
			this.boq.coatingSystem.coatProducts.splice(index, 1);
			this.selectedProductBrand.splice(index, 1);
			this.coatProductsValidators.splice(index, 1);
		}
	}

	/**
	 * Re-sets the spread and target rate for each coat product.
	 * Used on init, and whenever the height range is updated.
	 */
	private setCoatProductRates(): void {
		if (this.boq.coatingSystem) {
			for (let i = 0; i < this.boq.coatingSystem.coatProducts.length; i++) {
				const methodType = this.boq.coatingSystem.coatProducts[i].applicationMethod;
				// Since we only save the type, we need to find the full object method.
				if (this.applicationMethods[i] && this.boq.coatingSystem) {
					for (const method of this.applicationMethods[i]) {
						if (method.type === methodType) {
							this.applicationMethodSelected(method, i);
						}
					}
				}
			}
		}
	}

	/**
	 * Creates a blank coat product.
	 */
	private setNewCoatProduct(): void {
		this.newCoatProduct = {
			product: undefined,
			applicationMethod: undefined,
			percentage: 100,
			targetRate: 0,
			spreadRate: 0,
		};
	}

	/**
	 * Generates a form control for each element in the coating systems products array.
	 */
	private setupCoatProductsFormGroup(): void {
		if (!this.boq.coatingSystem || !this.boq.coatingSystem.coatProducts) {
			return;
		}
		this.coatProductsValidators = [];

		for (let i = 0; i < this.boq.coatingSystem.coatProducts.length; ++i) {
			this.coatProductsValidators[i] = GetBoqCoatingSystemProductValidation(this.isDisabled);
		}
	}

	/**
	 * Populates the application methods dropdown for each coat product on init.
	 */
	private setupInitialApplicationMethods(): void {
		if (this.boq && this.boq.coatingSystem && this.boq.coatingSystem.coatProducts && this.boq.coatingSystem.coatProducts.length) {
			for (let i = 0; i < this.boq.coatingSystem.coatProducts.length; i++) {
				const coatProduct = this.boq.coatingSystem.coatProducts[i];
				if (coatProduct.product && coatProduct.product.applicationMethods) {
					this.applicationMethods[i] = coatProduct.product.applicationMethods;
				} else {
					this.applicationMethods[i] = undefined;
				}
			}
		}
	}

	/**
	 * Retrieves products for the selected brand
	 */
	public getBrandProducts(index?: number): void {
		const brand = index !== undefined ? this.selectedProductBrand[index] : this.newSelectedProductBrand;
		if (brand) {
			if (!this.selectedBrandProducts[brand.id]) {
				this.selectedBrandProducts[brand.id] = [];
				this.loadingProductsPromise = this.productService.postList({ brand: brand.id }).toPromise();
				this.loadingProductsPromise
					.then(products => {
						this.selectedBrandProducts[brand.id] = products;
					})
					.catch(() => {});
			}
		}
	}

	/**
	 * Triggers calculation with parent component when a rate has been updated.
	 */
	public onRateChange() {
		for (const validation of this.coatProductsValidators) {
			const errors = ValidatorHelper.checkErrors(validation);
			if (errors) {
				this.snack.snackError(errors);
				return;
			}
		}
	}

	/**
	 * Populates the title field with a generated title
	 * @private
	 */
	public generateTitle(): void {
		console.log(this.boq)
		const title = this.getGeneratedTitle();
		if (title) {
			this.boq.coatingSystem.title = title;
		} else {
			this.snack.snackWarning(this.coatingSystemEditText.productRequired);
		}

	}

	/**
	 * Returns a title based on the product and substrate.
	 */
	private getGeneratedTitle(): string {
		if (this.boq.coatingSystem && this.boq.coatingSystem?.coatProducts[0] && this.boq.substrate) {
			return `${this.boq.coatingSystem.coatProducts[0].product.title} - ${this.boq.substrate.title}`;
		}
		return '';
	}

	/**
	 * Sets the initial brand filter to match the brand of the coat product
	 */
	private setupBrandFilters() {
		if (this.boq?.coatingSystem && this.boq?.coatingSystem?.coatProducts) {
			for (let i = 0; i < this.boq.coatingSystem.coatProducts.length; i++) {
				this.selectedProductBrand[i] = this.brands.find(brand => {
					const product = this.boq.coatingSystem.coatProducts[i].product;
					if (!product) {
						return false;
					}
					return brand.id === product.brand;
				});
				this.getBrandProducts(i);
			}
		}
	}

	protected readonly coatingSystemEditText = CoatingSystemEditText;
}
