import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { EstimatorUserTool } from '../../../../../../components/forms/estimator-user-tool-form/common/estimator-user-tool-form.models';
import { ChargePaginateResponse, DropdownData } from '../../../../../../dialog/components/charges/common/charges.interfaces';
import { Constants } from '../../../../../../helpers';
import { PostParams } from '../../../../../../interfaces';
import { BoqModel } from '../../../../../../models';
import { ChargeService } from '../../../../../../services/charge.service';
import { ProjectService } from '../../../../../../services/project.service';
import { EstimatorUserToolLibraryService } from '../../../../../estimator-user-tool-library/common/estimator-user-tool-library.service';
import { MeasuringToolPage } from '../../../measuring-tool.models';
import { MeasuringToolLayoutDataService } from './measuring-tool-layout.data.service';

@Injectable({
	providedIn: 'root',
})
export class MeasuringToolLayoutService {
	private currentPageId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public currentPageIdObservable: Observable<string> = this.currentPageId.asObservable();

	private projectBoqs: BehaviorSubject<BoqModel[]> = new BehaviorSubject<BoqModel[]>([]);
	public projectBoqsObservable: Observable<BoqModel[]> = this.projectBoqs.asObservable();

	private projectDropdowns: ReplaySubject<DropdownData> = new ReplaySubject();
	public projectDropdownsObservable: Observable<DropdownData> = this.projectDropdowns.asObservable();

	private projectPages: BehaviorSubject<MeasuringToolPage[]> = new BehaviorSubject<MeasuringToolPage[]>([]);
	public projectPagesObservable: Observable<MeasuringToolPage[]> = this.projectPages.asObservable();

	private allProjectPages: BehaviorSubject<MeasuringToolPage[]> = new BehaviorSubject<MeasuringToolPage[]>([]);
	public allProjectPagesObservable: Observable<MeasuringToolPage[]> = this.allProjectPages.asObservable();

	private selectedBoqId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
	public selectedBoqIdObservable: Observable<string> = this.selectedBoqId.asObservable();

	private userTools: ReplaySubject<EstimatorUserTool[]> = new ReplaySubject();
	public userToolsObservable: Observable<EstimatorUserTool[]> = this.userTools.asObservable();

	constructor(
		private chargeService: ChargeService,
		private measuringToolLayoutDataService: MeasuringToolLayoutDataService,
		private projectService: ProjectService,
		private userToolService: EstimatorUserToolLibraryService
	) {}

	public clearProjectObservables(): void {
		this.currentPageId.next(null);
		this.projectBoqs.next([]);
		this.projectDropdowns.next(null);
		this.projectPages.next([]);
		this.selectedBoqId.next(null);
	}

	/**
	 * Imports a number of pages to a variation from the main project
	 */
	public importPages(projectId: string, variationId: string, pages: MeasuringToolPage[]) {
		return this.measuringToolLayoutDataService.importPages(projectId, variationId, pages).pipe(
			tap(p => {
				const oldPages = this.projectPages.value;
				this.projectPages.next([...oldPages, ...p]);
			}),
			finalize(() => {
				this.setUpdatedProjectBoqs(projectId, false, variationId).subscribe();
			})
		);
	}

	/**
	 * Sets current page Id to Behaviour Subject
	 * @param id
	 */
	public setCurrentPageId(id: string): void {
		this.currentPageId.next(id);
	}

	/**
	 * Sets selected boq Id to Behaviour Subject
	 * @param id
	 */
	public setSelectedBoqId(id: string): void {
		this.selectedBoqId.next(id);
	}

	/**
	 * Sets current project projectBoqs array to Behaviour Subject
	 * @param projectId
	 * @param showInactiveCharges
	 * @param variationId
	 */
	public setUpdatedProjectBoqs(projectId: string, showInactiveCharges: boolean, variationId?: string): Observable<ChargePaginateResponse> {
		const params: PostParams = {
			chargeType: Constants.CHARGE_TYPES.Boq,
			project: projectId,
			variation: variationId,
		};

		if (!showInactiveCharges) {
			params.isActive = true;
		}

		return this.chargeService
			.postPaginateCharges(
				{
					limit: 0,
					skip: 0,
					params: params,
				},
				projectId
			)
			.pipe(
				tap(chargesData => {
					this.projectBoqs.next(chargesData.charges);
				})
			);
	}

	public setUpdatedProjectDropdowns(projectId: string): void {
		const subscription = this.projectService
			.getProjectFilterDropdowns(projectId, true)
			.pipe(
				finalize(() => {
					subscription.unsubscribe();
				}),
				tap((dropdownData: DropdownData) => {
					this.projectDropdowns.next(dropdownData);
				})
			)
			.subscribe();
	}

	/**
	 * Returns an observable for the allPages query.
	 * @param projectId
	 */
	public getAllProjectPages(projectId: string): Observable<MeasuringToolPage[]> {
		return this.measuringToolLayoutDataService.getAllPages(projectId, undefined, true).pipe(
			tap((pages: MeasuringToolPage[]) => {
				this.allProjectPages.next(pages);
			})
		);
	}

	/**
	 * Sets current project projectPages array to Behaviour Subject
	 * @param projectId
	 * @param variationId optionally filter by variation
	 */
	public setUpdatedProjectPages(projectId: string, variationId?: string): Observable<MeasuringToolPage[]> {
		return this.measuringToolLayoutDataService.getAllPages(projectId, variationId).pipe(
			tap((pages: MeasuringToolPage[]) => {
				this.projectPages.next(pages);
			})
		);
	}

	public setUpdatedUserTools(): void {
		const subscription = this.userToolService
			.postList({ isActive: true })
			.pipe(
				finalize(() => {
					subscription.unsubscribe();
				}),
				tap((userTools: EstimatorUserTool[]) => {
					this.userTools.next(userTools);
				})
			)
			.subscribe();
	}
}
