import { Placeholders, TEXT } from '../../../../../../helpers';

export const CoatingSystemEditText = {
	add: TEXT.add,
	actions: TEXT.actions,
	applicationMethod: TEXT.applicationMethod,
	applicationRate: TEXT.applicationRate,
	boq: TEXT.boq,
	coatingPercentage: TEXT.coatingPercentage,
	coatingPercentageToolTip: TEXT.coatingPercentageTooltip,
	coatingSystem: TEXT.coatingSystem,
	brandFilter: TEXT.brandFilter,
	generateTitle: TEXT.generateTitle,
	no: TEXT.no,
	save: TEXT.save,
	spreadRate: TEXT.spreadRate,
	percentage: TEXT.percentage,
	product: TEXT.product,
	productRequired: TEXT.productRequired,
	remove: TEXT.remove,
	requestSave: TEXT.requestToSave,
	select: TEXT.select,
	title: TEXT.title,
	toLibrary: TEXT.toLibrary,
};

export const CoatingSystemEditPlaceholders = {
	select: Placeholders.select,
};
