import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment/moment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ProjectModel, UserModel } from '../../../../models';
import { CommentService } from '../../../../services/comment.service';
import { LocalStorageService } from '../../../../services/local-storage.service';
import { NavigationService } from '../../../../services/navigation.service';
import { S3Service } from '../../../../services/s3.service';
import { projectStatuses } from '../../common/project.constants';

export enum UpdateType {
	Status,
	Comment,
}

@Component({
	selector: 'app-project-timeline',
	templateUrl: './project-timeline.component.html',
	styleUrls: ['./project-timeline.component.scss'],
})
export class ProjectTimelineComponent implements OnInit, OnDestroy {
	@Input() public project: ProjectModel;
	@Input() public modal: HTMLDivElement;
	@Input() public showModal: boolean;

	public timeline: any[] = [];
	public avatars: { [key: string]: Observable<string> } = {};
	public commentObservable: Observable<any>;
	public user: UserModel;
	public newCommentMessage: string;
	public newCommentIsInternal: boolean = true;
	private dragstartListener: (e: DragEvent) => void;

	protected readonly UpdateType = UpdateType;

	constructor(
		private s3: S3Service,
		private nav: NavigationService,
		private localStorage: LocalStorageService,
		private commentService: CommentService
	) {}

	public ngOnInit(): void {
		this.user = this.localStorage.getUserDetails();

		// build timeline array
		this.project.comments = this.project.comments.filter(c => c.sender && c.message); // filter null comments
		this.buildTimeline();
		this.getAvatarUrls();
		this.scrollToBottom();
		this.dragstartListener = (e: DragEvent) => {
			if (this.modal && !this.modal.contains(e.target as Element)) {
				e.preventDefault();
				e.stopPropagation();
			}
		};
		if (this.showModal && this.modal) {
			window.addEventListener('dragstart', this.dragstartListener);
		}
	}

	public ngOnDestroy() {
		window.removeEventListener('dragstart', this.dragstartListener);
	}

	public scrollToBottom(timeout?: number): void {
		// scrolls to bottom of comments
		// - added timeout as it looked strange instantly scrolling to the bottom after adding a comment
		if (this.modal && this.modal.scrollHeight) {
			setTimeout(() => (this.modal.scrollTop = this.modal.scrollHeight), timeout);
		}
	}

	public addComment() {
		// do not submit empty comments
		if (!this.newCommentMessage || this.newCommentMessage.length === 0) {
			return;
		}

		const newComment = {
			message: this.newCommentMessage,
			sender: this.user.id,
			isInternal: this.newCommentIsInternal,
			updatedAt: new Date(),
			project: this.project.id,
		};

		this.commentObservable = this.commentService.addComment(newComment, this.project.id).pipe(
			tap(comment => {
				this.project.comments.push(comment);
				this.newCommentMessage = '';
				this.buildTimeline();
				this.scrollToBottom(500);
			})
		);
	}

	public gotoComment(comment: any, event: MouseEvent): void {
		event.stopPropagation();
		event.preventDefault();
		this.nav.setRoute([`/project/${comment.id}/comment`]);
	}

	private buildTimeline() {
		const now = Date.now();

		// create default timeline (create date + comments)
		this.timeline = [
			{
				type: UpdateType.Status,
				color: projectStatuses.target.colour,
				message: 'Project created.',
				dateTime: new Date(this.project.createdAt),
				daysAgo: moment(now).diff(this.project.createdAt, 'days'),
			},
			...this.project.comments.map(c => {
				return {
					type: UpdateType.Comment,
					dateTime: new Date(c.updatedAt),
					daysAgo: moment(now).diff(c.updatedAt, 'days'),
					...c,
				};
			}),
		];

		// optionally include any additional status dates
		//Project Statuses
		if (this.project.submittedDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.submitted.colour,
				message: 'Project submitted.',
				dateTime: new Date(this.project.submittedDate),
				daysAgo: moment(now).diff(this.project.submittedDate, 'days'),
			});
		}

		if (this.project.status === 'declined' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.declined.colour,
				message: 'Project declined.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		if (this.project.status === 'active' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.active.colour,
				message: 'Project is active.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		if (this.project.status === 'quoteReady' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.quoteReady.colour,
				message: 'Quote ready.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		if (this.project.status === 'quoteApproved' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.quoteApproved.colour,
				message: 'Quote approved.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		if (this.project.wonDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.won.colour,
				message: 'Project won.',
				dateTime: new Date(this.project.wonDate),
				daysAgo: moment(now).diff(this.project.wonDate, 'days'),
			});
		}

		if (this.project.lostDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.lost.colour,
				message: 'Project lost.',
				dateTime: new Date(this.project.lostDate),
				daysAgo: moment(now).diff(this.project.lostDate, 'days'),
			});
		}

		if (this.project.status === 'live' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.live.colour,
				message: 'Project live.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		if (this.project.status === 'completed' && this.project.statusDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				color: projectStatuses.completed.colour,
				message: 'Project completed.',
				dateTime: new Date(this.project.statusDate),
				daysAgo: moment(now).diff(this.project.statusDate, 'days'),
			});
		}

		//Project Key Dates
		if (this.project.decisionDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.decisionDate.colour,
				message: 'Decision date.',
				dateTime: new Date(this.project.decisionDate),
				daysAgo: moment(now).diff(this.project.decisionDate, 'days'),
			})
		}

		if (this.project.completeCustomerProposal) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.decisionDate.colour,
				message: 'Complete customer proposal.',
				dateTime: new Date(this.project.completeCustomerProposal),
				daysAgo: moment(now).diff(this.project.completeCustomerProposal, 'days'),
			})
		}

		if (this.project.completeEstimate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.decisionDate.colour,
				message: 'Complete estimate.',
				dateTime: new Date(this.project.completeEstimate),
				daysAgo: moment(now).diff(this.project.completeEstimate, 'days'),
			})
		}

		if (this.project.managementToReviewQuote) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.managementToReviewQuote.colour,
				message: 'Management review.',
				dateTime: new Date(this.project.managementToReviewQuote),
				daysAgo: moment(now).diff(this.project.managementToReviewQuote, 'days'),
			})
		}

		if (this.project.quoteDueDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.quoteDueDate.colour,
				message: 'Quote due.',
				dateTime: new Date(this.project.quoteDueDate),
				daysAgo: moment(now).diff(this.project.quoteDueDate, 'days'),
			})
		}

		if (this.project.quoteStartDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.quoteStartDate.colour,
				message: 'Quote started.',
				dateTime: new Date(this.project.quoteStartDate),
				daysAgo: moment(now).diff(this.project.quoteStartDate, 'days'),
			})
		}

		if (this.project.quoteFinishDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.quoteFinishDate.colour,
				message: 'Quote finished.',
				dateTime: new Date(this.project.quoteFinishDate),
				daysAgo: moment(now).diff(this.project.quoteFinishDate, 'days'),
			})
		}

		if (this.project.submitToCustomer) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.submitToCustomer.colour,
				message: 'Submit to customer.',
				dateTime: new Date(this.project.submitToCustomer),
				daysAgo: moment(now).diff(this.project.submitToCustomer, 'days'),
			})
		}

		if (this.project.projectStartDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.lost.colour,
				message: 'Project started.',
				dateTime: new Date(this.project.projectStartDate),
				daysAgo: moment(now).diff(this.project.projectStartDate, 'days'),
			});
		}

		if (this.project.projectFinishDate) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.lost.colour,
				message: 'Project finished.',
				dateTime: new Date(this.project.projectFinishDate),
				daysAgo: moment(now).diff(this.project.projectFinishDate, 'days'),
			});
		}

		if (this.project.updatedAt) {
			this.chronoInsert(this.timeline, {
				type: UpdateType.Status,
				// color: projectStatuses.updatedAt.colour,
				message: 'Last updated.',
				dateTime: new Date(this.project.updatedAt),
				daysAgo: moment(now).diff(this.project.updatedAt, 'days'),
			})
		}
	}

	private chronoInsert(timeline: any[], update: any) {
		let low = 0,
			high = timeline.length;

		while (low < high) {
			const mid = Math.floor((low + high) / 2); // Correct the midpoint calculation
			const midUpdate = new Date(timeline[mid].dateTime);

			if (midUpdate.getTime() < update.dateTime.getTime()) {
				low = mid + 1;
			} else {
				high = mid;
			}
		}

		// Insert the element into the timeline at the found index
		timeline.splice(low, 0, update);
	}

	private getAvatarUrls(): void {
		// get the current user's avatar
		this.avatars[this.user.id] = this.getSignedUrl(this.user);
		for (const comment of this.project.comments) {
			this.avatars[comment.sender._id] = this.getSignedUrl(comment.sender);
		}
	}

	private getSignedUrl(user: UserModel): Observable<string> {
		if (user?.imageUrl) {
			return of(user.imageUrl);
		} else if (user?.displayPictureKey) {
			return this.s3.getSignedUrl(user.displayPictureKey);
		}

		return undefined;
	}
}
