import { Validators } from '@angular/forms';

import { ValidatorHelper, CustomValidators } from '../helpers';
import { BulkCharge } from './charge';
import { EmbeddedCoatProduct } from './embedded-coat-product';
import { EmbeddedRate } from './embedded-rate';
import { Product } from './product';
import { ReferenceCode } from './reference-code';

// Coating System object
export interface CoatingSystem {
	application?: string;
	brand: string;
	chargeOutRates?: EmbeddedRate[];
	coatProducts: EmbeddedCoatProduct[];
	code?: string;
	id?: string;
	isActive: boolean;
	isSelected?: boolean;
	newWork?: string;
	previouslyPainted?: string;
	product: Product;
	substrate: ReferenceCode;
	title: string;
}

export function GetBoqCoatingSystemValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Title',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Substrate',
			validators: [Validators.required],
		},
		{
			name: 'Product',
			validators: [Validators.required],
		},
		{
			name: 'CoatProducts',
			validators: [CustomValidators.minLengthArray(1)],
		},
	]);
}

export function GetBoqCoatingSystemCardValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Title',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
	]);
}

export function GetCoatingSystemValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'Code',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Title',
			validators: [Validators.required, Validators.maxLength(255), CustomValidators.noWhiteSpaceValidator],
		},
		{
			name: 'Substrate',
			validators: [Validators.required],
		},
		{
			name: 'Product',
			validators: [Validators.required],
		},
		{
			name: 'NewWork',
			validators: [Validators.maxLength(3000)],
		},
		{
			name: 'PreviouslyPainted',
			validators: [Validators.maxLength(3000)],
		},
		{
			name: 'Application',
			validators: [Validators.maxLength(3000)],
		},
		{
			name: 'ChargeOutRates',
			validators: [CustomValidators.arrayItemsUnique('Charge-out rate states')],
		},
		{
			name: 'CoatProducts',
			validators: [CustomValidators.minLengthArray(1)],
		},
	]);
}

export function GetCoatingSystemProductValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'ApplicationMethod',
			validators: [Validators.required],
		},
		{
			name: 'Product',
			validators: [Validators.required],
		},
		{
			name: 'Percentage',
			validators: [Validators.required, Validators.min(10), Validators.max(100)],
		},
		{
			name: 'SpreadRate',
			validators: [Validators.required, Validators.min(0)],
		},
		{
			name: 'ApplicationRate',
			validators: [Validators.required, Validators.min(0)],
		},
	]);
}

export function GetBoqCoatingSystemProductValidation(isDisabled: boolean) {
	return ValidatorHelper.formBuilder(
		[
			{
				name: 'ApplicationMethod',
				validators: [Validators.required],
			},
			{
				name: 'Product',
				validators: [Validators.required],
			},
			{
				name: 'Percentage',
				validators: [Validators.required, Validators.min(10), Validators.max(100)],
			},
			{
				name: 'SpreadRate',
				validators: [Validators.required, Validators.min(0)],
			},
			{
				name: 'TargetRate',
				validators: [Validators.required, Validators.min(0)],
			},
		],
		isDisabled
	);
}

export function GetChargeOutRateValidation() {
	return ValidatorHelper.formBuilder([
		{
			name: 'State',
			validators: [Validators.required],
		},
		{
			name: 'RateEBA',
			validators: [Validators.required, Validators.min(0)],
		},
		{
			name: 'RateNonEBA',
			validators: [Validators.required, Validators.min(0)],
		},
	]);
}

export function GetBulkBoqCloneValidation(bulkCharge?: BulkCharge) {
	return ValidatorHelper.formBuilder([
		{
			name: 'SubLocation1',
			validators: [],
			value: bulkCharge.bulkBoq.subLocation1,
		},
		{
			name: 'SubLocation2',
			validators: [],
			value: bulkCharge.bulkBoq.subLocation2,
		},
		{
			name: 'SubLocation3',
			validators: [],
			value: bulkCharge.bulkBoq.subLocation3,
		},
	]);
}

export function GetBulkAdditionalChargeCloneValidation(bulkCharge: BulkCharge) {
	return ValidatorHelper.formBuilder([
		{
			name: 'SubLocation1',
			validators: [],
			value: bulkCharge.bulkAdditionalCharge.subLocation1,
		},
	]);
}
