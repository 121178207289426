export enum Feature {
	DeveloperMode = 'developer_mode',
	ScheduleTool= 'schedule_tool',
	MeasureTool = 'measure_tool',
	Reporting = 'reporting',
}

export const DEFAULT_FEATURES: Record<Feature, boolean> = {
	[Feature.ScheduleTool]: false,
	[Feature.DeveloperMode]: false,
	[Feature.MeasureTool]: true,
	[Feature.Reporting]: false,
};
