import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';

import { Constants, PageJumpHelper } from '../helpers';
import { NavigationService } from './navigation.service';

@Injectable()
export class GenericLibraryService {
	private refreshSubject = new Subject<number>();
	public refreshObs: Observable<number> = this.refreshSubject.asObservable();

	public queryId: string;
	public queryBrandId: string;
	public queryCodeType: string;
	public queryCompanyId: string;
	public queryFolderKey: string;

	private navConst = Constants.NAVIGATION_TYPES;

	constructor(private navigationService: NavigationService) {}


	public setBrand(brandId: string): void {
		this.queryBrandId = brandId;
	}

	public clearBrand() {
		this.queryBrandId = null;
	}

	/**
	 * Signals the generic library list to refresh the page.
	 */
	public refreshList(pageNo?: number): void {
		this.refreshSubject.next(pageNo);
	}

	public clearIdQuery(): void {
		PageJumpHelper.pageJump(Constants.LIBRARY_COMPONENTS.list);
		this.navigationService.removeQueryParam(Constants.NAVIGATION_TYPES.id);
	}

	public getQueryParams(): void {
		this.queryBrandId = this.navigationService.getQueryParam(this.navConst.brand);
		this.queryCodeType = this.navigationService.getQueryParam(this.navConst.type);
		this.queryCompanyId = this.navigationService.getQueryParam(this.navConst.company);
		this.queryFolderKey = this.navigationService.getQueryParam(this.navConst.folder);
		this.queryId = this.navigationService.getQueryParam(this.navConst.id);
	}
}
