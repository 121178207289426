import { Injectable } from '@angular/core';
import { RouterEvent } from '@angular/router';
import { Intercom } from '@supy-io/ngx-intercom';

import { Observable, Subject } from 'rxjs';

import { environment } from '../../environments/environment';
import { Constants } from '../helpers';
import { UserModel } from '../models';
import { HttpClient } from '@angular/common/http';

export interface IntercomCollection {
	id: string;
	workspace_id: string;
	name: string;
	url: string;
	order: string;
	created_at: number;
	updated_at: number;
	description: string | null;
	icon: string;
	parent_id: string | null;
	help_center_id: number | null;
	articleCount: number;
}

interface IntercomCollectionResponse {
	type: string;
	collections: IntercomCollection[];
}

@Injectable({
	providedIn: 'root',
})
export class IntercomService {
	public intercomStateChangeObservable: Observable<boolean>;
	private isIntercomActiveSubject: Subject<boolean> = new Subject<boolean>();

	constructor(private intercom: Intercom, private http: HttpClient) {
		// Initialise state change observable
		this.emitIntercomStateChange(false);
	}

	/**
	 * Opens intercom with a prefilled message
	 * @param message
	 */
	public message(message: string): void {
		this.intercom.showNewMessage(message);
	}

	/**
	 * Open Intercom
	 */
	public openIntercom(user: UserModel): void {
		// open Intercom
		if (this.intercom) {
			this.intercom.boot({
				alignment: 'left',
				app_id: environment.intercomAppId,
				custom_launcher_selector: '#intercomLauncher',
				email: user.email,
				//entity: user.entity.email,
				//entityUrl: `${STRIPE_CUSTOMER_URL}/${user.entity.stripeId}`,
				hide_default_launcher: true,
				name: user.firstName + ' ' + user.lastName,
				user_hash: user.intercomUserHash,
				user_id: user.id,
			});

			// Emit intercom state change
			this.emitIntercomStateChange(true);
		}
	}

	/**
	 * Shut intercom down
	 */
	public shutIntercomDown(): void {
		if (this.intercom) {
			this.intercom.shutdown();

			// Emit intercom state change
			this.emitIntercomStateChange(false);
		}
	}

	/**
	 * Update Intercom
	 * @param event RouterEvent
	 */
	public updateIntercom(event: RouterEvent): void {
		if (this.intercom) {
			this.intercom.trackEvent('Viewed Page', {
				url: event.url,
			}); // track usage
			this.intercom.update({}); // refresh messenger data
		}
	}

	/**
	 * Emit Intercom active state change
	 */
	private emitIntercomStateChange(isActive: boolean): void {
		this.isIntercomActiveSubject.next(isActive);
		this.intercomStateChangeObservable = this.isIntercomActiveSubject.asObservable();
	}

	public getIntercomCollections(): Observable<IntercomCollectionResponse> {
		return this.http.get<IntercomCollectionResponse>(`${Constants.BASE_API_URL}/help-centre/collections`)
	}
}
