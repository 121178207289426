import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	DestroyRef, ElementRef,
	EventEmitter,
	inject, Input,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
	NavigationMenuItem,
} from '../../../@itc-core/components/navigation-panels/navigation-menu/common/navigation-menu.interfaces';
import { PopoverComponent } from '../../../@mos-core/components/popover/popover.component';
import { LocalStorageService } from '../../services/local-storage.service';
import { S3Service } from '../../services/s3.service';
import { ProjectModel, UserModel } from '../../models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectService } from '../../services/project.service';
import { Project, ProjectsFullListSearchResponse } from '../../interfaces';
import { debounceTime, filter, Subject, Subscription } from 'rxjs';
import { NavigationService } from '../../services/navigation.service';
import { AuthService } from '../../services/auth.service';
import { AlertService } from '../../pages/alert/common/alert.service';
import { environment } from '../../../environments/environment';
import { SelectedProjectService } from '../../services/select-project.service';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { Feature } from '../../helpers/feature';
import { RolePermissionsService } from '../../services/role-permissions.service';
import { getSteps as welcomeSteps } from './welcome.tour.config';
import { UserService } from '../../services/user.service';
import { ProductTourService } from '../../services/product-tour.service';
import { ShepherdService } from 'angular-shepherd';
import { getSteps as defaultUserSteps } from '../sidebar/user-menu.tour.config';
import { getSteps as defaultSideBarSteps } from '../sidebar/sidebar.tour.config'

interface NavRoute {
	name: string;
	route: string;
	subRoutes?: NavRoute[];
	isExpanded?: boolean;
	icon?: string;
	disabled?: boolean;
	hidden?: boolean;
}

@Component({
	selector: 'paint-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
}) export class SidebarComponent implements OnInit, AfterViewInit {
	@Output() public navigationItemClicked: EventEmitter<NavigationMenuItem> = new EventEmitter();
	@Output() public commandPaletteOpened: EventEmitter<void> = new EventEmitter();
	@ViewChild('userPopover') public userPopover!: PopoverComponent;
	@ViewChild('projectPopover') public projectPopover!: PopoverComponent;
	@ViewChild('mobileProjectPopover') public mobileProjectPopover!: PopoverComponent;
	@ViewChild('searchInput') searchInput!: ElementRef<HTMLInputElement>;

	@Input() public canCreateNewProject: any;

	public isProjectExpanded: boolean = true;
	public navRoutes:  NavRoute[] = []
	public projectRoutes: NavRoute[]  = []
	public userRoutes: NavRoute[]  = []
	public currentUser: UserModel;
	public userFullName: string;
	public initials: string;
	public avatarUrl: string | null = null;
	public projects: (ProjectModel | Project)[] = [];
	public loadingProjects: boolean = false;
	public destroyRef = inject(DestroyRef)
	public selectedProject: Project;
	private searchSubject = new Subject<string>();
	public fuzzySearchString: string = "";
	public emptyState: boolean = false;
	public loadingSearchProjects: Subscription;
	public isCollapsed: boolean = false;
	public isHovered: boolean = false;
	public isEntityOwner: boolean = false;
	public totalAlerts: number = 0;
	public userRole: string;
	public isEntityActive: boolean = false;
	public isMobileMenuOpen: boolean = false;
	public isContractsAndAbove: boolean = false;
	public isNewProjectButtonDisabled: boolean = false;
	public selectedEnvironment = environment.env;
	public searchHotKey = 'Ctrl + K';

	// Feature or Route Permissions
	public canUploadCustomDocuments: boolean = false;
	public hasMeasuringToolAccess: boolean = false;

	constructor(private router: Router,
				private localStorageService: LocalStorageService,
				private s3Service: S3Service,
				private cdr: ChangeDetectorRef,
				private projectService: ProjectService,
				private navigationService: NavigationService,
				private authService: AuthService,
				private alertService: AlertService,
				private selectedProjectService: SelectedProjectService,
				private featureFlagService: FeatureFlagService,
				public rolePermissions: RolePermissionsService,
				private userService: UserService,
				private productTourService: ProductTourService,
				private shepherdService: ShepherdService,
	) {
		this.searchSubject.pipe(
			debounceTime(200),
			takeUntilDestroyed(this.destroyRef)
		).subscribe(searchTerm => {
			this.fuzzySearchString = searchTerm;
			this.fuzzySearch();
		})
	}

	public ngOnInit(): void {
		// Initialise user, projects and routes
		this.initialiseUser();
		this.initialiseStandardRoutes();
		this.initialiseUserRoutes();
		this.getLocalProject();

		// Collapse expanded routes on navigation
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			// Check if the URL contains '/project/:id'
			const projectIdMatch = event.urlAfterRedirects.match(/\/project\/([^/]+)/);
			if (projectIdMatch && projectIdMatch[1]) {
				const projectId = projectIdMatch[1];
				if (!this.selectedProject || this.selectedProject && this.selectedProject?.id !== projectId) {
					this.projectService.getProject(projectId)
						.pipe(takeUntilDestroyed(this.destroyRef))
						.subscribe(selectProject => {
								this.setSelectedProject(selectProject)
								this.initialiseProjectRoutes();
							}
						);
				}
			}
			this.collapseExpandedRoutes();
		});

		// Get total alerts
		this.alertService.alerts.subscribe(total => {
			this.totalAlerts = total;
		});

		if (navigator.appVersion.indexOf('Mac') !== -1) {
			this.searchHotKey = '⌘K';
		} else {
			this.searchHotKey = 'Ctrl + K';
		}

		// Persist sidebar state using local storage
		const sidebarState = localStorage.getItem('sidebarCollapsed');
		this.isCollapsed = sidebarState === 'true';
	}

	public ngAfterViewInit() {
		setTimeout(() => {
			if (this.currentUser) {
				this.userService.getUserTours(this.currentUser.id).subscribe(
					(response: any) => {
						if (!response.tours.Welcome && !this.shepherdService.isActive) {
							this.productTourService.setTour('Welcome')
							this.productTourService.showSteps(welcomeSteps(this.shepherdService, this.productTourService), true);
						}
					}
				);

				this.productTourService.activeTourSubject.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(tourId => {
					if (tourId) {
						this.shepherdService.defaultStepOptions = {
							scrollTo: true,
							cancelIcon: {
								enabled: true,
							},
						};
						this.shepherdService.modal = true;
						const relatedTours = ['Account', 'Companies', 'Users', 'Divisions', 'Help'];
						const scheduleToolTours: string[] = ['My Shifts', 'Project/Location', 'Resources', 'Schedule', 'Timesheets'];
						if (!this.shepherdService.isActive) {
							if (relatedTours.includes(tourId)) {
								this.productTourService.showSteps(defaultUserSteps(this.router, this.shepherdService, this.productTourService), false);
							} else if (scheduleToolTours.includes(tourId) || tourId === 'Clients') {
								this.productTourService.showSteps(defaultSideBarSteps(this.productTourService, this.shepherdService), false);
							}
						}
					}
				});
			}
		}, 25);



		this.router.events
			.pipe(
				filter(event => event instanceof NavigationStart),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((event: NavigationStart) => {
				if (event.navigationTrigger === 'popstate') {
					if (this.shepherdService.isActive) {
						this.shepherdService.cancel();
					}
				}
			})
	}

	private getLocalProject() {
		this.selectedProjectService.getSelectedProject()
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(project => {
				this.selectedProject = project;
				this.initialiseProjectRoutes();
			});
	}

	private initialiseUser() {
		this.currentUser = this.localStorageService.getUserDetails();
		this.userRole = this.localStorageService.getUserDetails().role;
		this.isEntityOwner = this.authService.isEntityOwner();
		this.isEntityActive = this.authService.isEntityActive();
		this.userFullName = this.currentUser.fullName;
		this.isContractsAndAbove = this.rolePermissions.isContractsAndAbove();
		this.isNewProjectButtonDisabled = !(this.rolePermissions.isTrainee() || this.rolePermissions.isUser() || this.rolePermissions.isTeamLeaderAndAbove()) || !this.canCreateNewProject;
		this.getPictureUrl();
	}

	private initialiseProjectRoutes() {
		this.hasMeasuringToolAccess = this.currentUser.entity?.hasMeasuringToolAccess;

		this.projectRoutes = [
			{ name: 'Overview', route: `project/${this.selectedProject?.id}/overview` },
			{ name: 'Charges', route: `project/${this.selectedProject?.id}/charges` },
			{ name: 'Variations', route: `project/${this.selectedProject?.id}/variations` },
			{
				name: 'Measuring Tool',
				route: `measuring-tool/${this.selectedProject?.id}`,
				disabled: !this.hasMeasuringToolAccess,
				hidden: false,
			},
			{ name: 'Applications Rates', route: `project/${this.selectedProject?.id}/application-rates` },
			{ name: 'Inclusions/Exclusions', route: `project/${this.selectedProject?.id}/inclusions-exclusions` },
			{ name: 'Labour Costs', route: `project/${this.selectedProject?.id}/labour-costs` },
			{ name: 'Discounts', route: `project/${this.selectedProject?.id}/discounts` },
			{
				name: 'Export Documents',
				route: `project/${this.selectedProject?.id}/export`,
				subRoutes: [
					{ name: 'Tender Document', route: `project/${this.selectedProject?.id}/export/tender-document` },
					{ name: 'Quote Proposal', route: `project/${this.selectedProject?.id}/export/quote-proposal` },
					{ name: 'Office Folder', route: `project/${this.selectedProject?.id}/export/office-folder` },
					{ name: 'Site Folder', route: `project/${this.selectedProject?.id}/export/site-folder` },
					{ name: 'Safety Folder', route: `project/${this.selectedProject?.id}/export/safety-folder` },
					{ name: 'QA Folder', route: `project/${this.selectedProject?.id}/export/qa-folder` },
					{ name: 'Variation Quote', route: `project/${this.selectedProject?.id}/export/variation-quote` },
					{ name: 'Quote', route: `project/${this.selectedProject?.id}/export/quote` },
					{ name: 'Bill of Quantity', route: `project/${this.selectedProject?.id}/export/boq-export` },
				]
			},
			{ name: 'Comments', route: `project/${this.selectedProject?.id}/comment` },
			{ name: 'Attachments', route: `project/${this.selectedProject?.id}/attachments` },
			{ name: 'Emails/Approvals', route: `project/${this.selectedProject?.id}/emails` },
			{ name: 'Tasks', route: `project/${this.selectedProject?.id}/tasks` },
			{ name: 'Time', route: `project/${this.selectedProject?.id}/time` },
			{ name: 'Project Invoicing', route: `project/${this.selectedProject?.id}/invoice` },
			{ name: 'Product Purchasing', route: `project/${this.selectedProject?.id}/product-purchasing` },
			{ name: 'Versions', route: `project/${this.selectedProject?.id}/versions` },
		];
		this.cdr.detectChanges();
	}

	private initialiseStandardRoutes() {
		this.canUploadCustomDocuments = this.currentUser.entity.canUploadCustomDocuments;

		this.navRoutes = [
			{ name: 'Dashboard', route: '/', icon: 'home', disabled: false, hidden: false },
			{ name: 'Projects', route: '/projects', icon: 'folder-closed', disabled: false, hidden: false },
			{ name: 'Clients', route: '/clients', icon: 'users', disabled: false, hidden: false },
			{
				name: 'Reports',
				route: '/reports',
				subRoutes: [
					{ name: 'Targeting', route: '/reports/targeting' },
					{ name: 'Forecasting', route: '/reports/forecasting' },
					{ name: 'Sales', route: '/reports/sales' },
					{ name: 'Budgets', route: '/reports/budgets' },
				],
				icon: 'chart-column',
				disabled: !this.featureFlagService.has(Feature.Reporting),
				hidden: false
			},
			{ name: 'Invoices', route: '/invoices', icon: 'file', disabled: false, hidden: !this.isContractsAndAbove },
			{
				name: 'Schedule',
				route: '/schedule',
				subRoutes: [
					{ name: 'My Shifts', route: '/shifts' },
					{ name: 'Project/Location', route: '/schedule/projects' },
					{ name: 'Resources', route: '/schedule/resources' },
					{ name: 'Schedule', route: '/schedule/schedule-view' },
				],
				isExpanded: false,
				icon: 'calendar',
				disabled: !this.featureFlagService.has(Feature.ScheduleTool),
				hidden: false
			},
			{
				name: 'Libraries',
				route: '/libraries',
				subRoutes: [
					{ name: 'Brands', route: '/libraries/brands' },
					{ name: 'Coating Systems', route: '/libraries/coating-systems' },
					{ name: 'Colours', route: '/libraries/colours' },
					{ name: 'Measure Tools', route: '/libraries/measureTools' },
					{ name: 'Competitors', route: '/libraries/competitors' },
					{
						name: 'Files/Folders',
						route: '/libraries/files-folders/tender-document',
						hidden: !this.canUploadCustomDocuments,
						subRoutes: [
							{ name: 'Tender Document', route: '/libraries/files-folders/tender-document' },
							{ name: 'Quote Proposal', route: '/libraries/files-folders/quote-proposal' },
							{ name: 'Office Folder', route: '/libraries/files-folders/office-folder' },
							{ name: 'Site Folder', route: '/libraries/files-folders/site-folder' },
							{ name: 'Safety Folder', route: '/libraries/files-folders/safety-folder' },
							{ name: 'QA Folder', route: '/libraries/files-folders/qa-folder' },
							{ name: 'Variation Quote', route: '/libraries/files-folders/variation-quote' },
							{ name: 'Quote', route: '/libraries/files-folders/quote' },
							{ name: 'Bill of Quantity', route: '/libraries/files-folders/boq' },
							{ name: 'Product Purchasing', route: '/libraries/files-folders/product-purchasing' },
							{ name: 'Invoice', route: '/libraries/files-folders/invoice' },
						]
					},
					{ name: 'Inclusions/Exclusions', route: '/libraries/inclusions-exclusions' },
					{ name: 'Labour Rates', route: '/libraries/labour-rates' },
					{ name: 'Products', route: '/libraries/products' },
					{ name: 'Suppliers', route: '/libraries/suppliers' },
					{ name: 'Marketing Items', route: '/libraries/marketing-items' },
				],
				icon: 'table',
				disabled: false,
				hidden: !this.isContractsAndAbove
			},
		];
	}

	private initialiseUserRoutes() {
		this.userRoutes = [
			{ name: 'Alerts', route: '/alerts', icon: 'bell' },
			{ name: 'Profile', route: '/user', icon: 'user' },
			{ name: 'Settings', route: '/admin/account-details', icon: 'settings' },
			{ name: 'Help', route: 'https://intercom.help/paintprojex', icon: 'life-buoy' },
		];

		if (this.isEntityOwner) {
			this.userRoutes.push({ name: 'Subscription', route: '/subscription', icon: 'badge-dollar-sign' });
		}
	}

	public userMenuSelection(route: NavRoute, event: MouseEvent) {
		event.stopPropagation();
		if (route.route.startsWith('https')) {
			window.open(route.route, '_blank');
		} else {
			this.navigationService.setRoute([route.route]);
		}
		this.userPopover.close();
		this.toggleMobileMenu();
	}

	public onSearch(event: any) {
		this.fuzzySearchString = event.target.value.toLowerCase();
		this.emptyState = false;
		this.projects = [];

		if (!this.fuzzySearchString || this.fuzzySearchString.length === 0) {
			this.projects = [];
			this.emptyState = false;
			this.loadingProjects = false;
			return;
		}

		if (this.fuzzySearchString === '?') {
			this.emptyState = false;
			return;
		}

		this.searchSubject.next(this.fuzzySearchString)
	}

	public fuzzySearch(): void {
		this.loadingProjects = true;
		this.loadingSearchProjects = this.projectService
			.getFullProjectList(this.fuzzySearchString)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((result: ProjectsFullListSearchResponse) => {
				if (result.projects && result.projects.length > 0) {
					this.projects = result.projects;
					this.emptyState = false;
					this.loadingProjects = false;
				} else {
					this.projects = [];
					this.emptyState = true;
					this.loadingProjects = false;
				}
			});
	}

	public setSelectedProject(project: Project): void {
		this.selectedProject = project;
		this.selectedProjectService.setSelectedProject(project);
		this.mobileProjectPopover.close();

		const currentUrl = this.router.url;

		if (currentUrl.includes('project')) {
			const path = currentUrl.split('/').pop();

			this.router.navigate([`/project/${project.id}/${path}`]);
		} else {
			this.router.navigate([`/project/${project.id}/overview`]);
		}
	}

	public expandProjectRoutes(): void {
		this.isProjectExpanded = !this.isProjectExpanded;
	}

	public handleNavigation(route: NavRoute): void {
		if (route.disabled) {
			this.router.navigate(['/subscription']);
			this.toggleMobileMenu();
			return;
		}

		if (route.subRoutes && !route.disabled) {
			route.isExpanded = !route.isExpanded;
		} else {
			this.router.navigate([route.route]);
		}
		this.toggleMobileMenu();
	}

	public handleProjectNavigation(route: NavRoute): void {
		if (route.disabled) {
			this.router.navigate(['/subscription']);
			return;
		}

		if (route.subRoutes) {
			this.toggleSubRoutes(route, new Event('click'));
		} else {
			this.router.navigate([route.route]);
		}
	}

	public toggleSubRoutes(route: NavRoute, event: Event): void {
		event.stopPropagation();
		if (!route.disabled) {
			route.isExpanded = !route.isExpanded;
		}
		this.cdr.detectChanges();
	}

	private collapseExpandedRoutes() {
		const currentUrl = this.router.url;
		this.navRoutes.forEach(route => {
			if (route.subRoutes) {
				if (!currentUrl.startsWith(route.route)) {
					route.isExpanded = false;
				}
			}
		});
		this.cdr.detectChanges();
	}

	public openUserPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.userPopover.open(event);
	}

	public openMobileProjectPopover(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();
		this.mobileProjectPopover.open(event);
	}

	public getPictureUrl() {
		if (this.currentUser && this.currentUser.displayPictureKey) {
			this.s3Service.getSignedUrl(this.currentUser.displayPictureKey).subscribe(url => {
				this.avatarUrl = url;
				this.cdr.detectChanges();
			})
		} else {
			this.getInitials();
		}
	}

	public getInitials(): string {
		if (!this.currentUser || !this.userFullName) {
			return '';
		}
		const [firstName, lastName] = this.currentUser.fullName.split(' ');
		this.initials = `${firstName[0]} ${lastName[0]}`;
	}

	public toggleSidebar(event: MouseEvent): void {
		event.stopPropagation()
		this.isCollapsed = !this.isCollapsed;
		localStorage.setItem('sidebarCollapsed', this.isCollapsed.toString());
		if (this.isCollapsed) {
			this.isHovered = false;
		}
		this.cdr.detectChanges();
	}

	public onMouseEnter(): void {
		if (this.isCollapsed) {
			this.isHovered = true;
			this.cdr.detectChanges();
		}
	}

	public onMouseLeave(): void {
		if (this.isCollapsed) {
			this.isHovered = false;
			this.cdr.detectChanges();
		}
	}

	public toggleMobileMenu() {
		this.isMobileMenuOpen = !this.isMobileMenuOpen;
	}

	public closeMobileMenu() {
		this.isMobileMenuOpen = false;
	}

	public openCommandPalette() {
		console.log('open command palette')
		this.commandPaletteOpened.emit()
	}

	protected readonly environment = environment;
}
